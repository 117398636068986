////
/// @group _config.scss
////



// ==================================
//  Брейкпоинты
// ==================================

/// Start - учитывается в карте брейкпоинтов
/// - iPhone 5 `320px`
/// - Nokia Lumia `320px`
/// - Nexus 5 `360px`
/// - Nokia N9 `360px`
/// - Galaxy S5 `360px`
/// @type Size
$st: 320px;

/// Extra Small
/// - Mobile M `375px`
/// - iPhone 6 `375px`
/// - Nexus 4 `384px`
/// - Nexus 6 `412px`
/// - Mobile L `425px`
/// - iPhone 6 plus `414px`
/// @type Size
$xs: 375px;

/// Small
/// - iPhone 4 (landscape) `480px`
/// - Nokia Lumia (landscape) `533px`
/// - iPhone 5 (landscape) `568px`
/// @type Size
$sm: 480px;

/// Medium Small
/// - Nexus 4 (landscape) `640px`
/// - Nexus 5 (landscape) `640px`
/// - Nokia N9 (landscape) `640px`
/// - Galaxy S5 (landscape) `640px`
/// - iPhone 6 (landscape) `667px`
/// - Nexus 7 `700px`
/// - Nexus 6 (landscape) `732px`
/// - iPhone 6 Plus (landscape) `736px`
/// @type Size
$ms: 640px;

/// Medium
/// - iPad `768px`
/// - iPad Mini `768px`
/// - Nexus 10 `800px`
/// - Nexus 7 `900px`
/// - Laptop (landscape) `950px`
/// @type Size
$md: 768px;

/// Default / Normal
/// - Laptop `1024px`
/// - iPad (landscape) `1024px`
/// - iPad Mini (landscape) `1024px`
/// @type Size
$def: 1024px;

/// Large
/// - Laptop (landscape) `1280px`
/// - Nexus 10 (landscape) `1280px`
/// @type Size
$lg: 1280px;

/// Extra Large - не учитывается в карте брейкпоинтов
/// - iPad Pro (landscape) `1366` ~ "15.6
$xl: 1366px;

/// Mega - не учитывается в карте брейкпоинтов
/// - Laptop L `1440px`
/// @type Size
$mg: 1440px;

/// Карта брейкпоинтов.
/// Используеться при генерации, в цыкле,
/// модификаторов под каждый медиа запрос
///
/// Как правило, результатом таких циклов
///  - есть селекторы по схеме `{PREFIX}--$point{-SUFFIX}`,
///  или, в случае с хелперами - `_$point-{PREFIX}`.
///
/// Пример на ячейке сетки -> основной селектор `.gcell`
/// с модификаторами получаем:
/// - .gcell--xs-6
/// - .gcell--sm-4
/// - .gcell--md-3
/// - .gcell--def-2
/// - .gcell--lg-1
///
/// Пример на хелпере `._show`:
/// - ._xs-show
/// - ._sm-show
/// - ._md-show
/// - ._def-show
/// - ._lg-show
///
/// @type Map
$breakpoints: (
 	st: $st,
	xs: $xs,
	sm: $sm,
	ms: $ms,
	md: $md,
	def: $def,
	lg: $lg,
	xl: $xl,
	// mg: $mg,

);




// ==================================
//  Root
// ==================================

/// Медиа запрос на котором меняется размер шрифта у `html`
/// В этом запросе - все величины rem'ах изменяться автоматически
/// @type Size
$root-mq: $md;

/// Размер текста у `html` при максимальной ширине $root-mq
/// @type Size
$rem-size: 12px;

/// Размер текста у `html` при минимальной ширине $root-mq
/// @type Size
$rem-size-mq: 16px;




// ==================================
//  Шрифты
// ==================================

/// Основной шрифт
/// по умолчанию будет добавлен к `html`
/// @type Keyword
$font-family: "MuseoSansCyrl", sans-serif;


/// Дополнительный шрифт
/// @type Keyword
$font-family--sub: sans-serif;

/// Шрифт "кода"
/// по умолчанию будет добавлен к `pre, code, samp, kbd`
/// @type Keyword
$font-family--monospace: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;




// ==================================
//  Отступы
// ==================================

/// Разделитель
/// @type String
$delimiter: '-';

/// Отступ
/// @type Size
$gutter: 20px;

/// Карта коэфициентов для отступов
/// Каждая единица будет умножена $gutter
///
/// Если $gutter = 20px,
/// то, к примеру для хелепера паддинга, получаем:
/// - _p-sm  = 5px;
/// - _p-md  = 10px;
/// - _p-def = 20px;
/// - _p-lg  = 30px;
/// - _p-xl  = 40px;
/// - _p-mg  = 50px;
/// - _p-hg  = 70px;
///
/// @require $delimiter
/// @type Map
$gutter-factor: (
    #{$delimiter}sm: 0.25,
    #{$delimiter}md: 0.5,
    #{$delimiter}def: 1,
    #{$delimiter}lg: 1.5,
    #{$delimiter}xl: 2,
    #{$delimiter}mg: 2.5,
    #{$delimiter}hg: 3.5
);




// ==================================
// Цвета
// ==================================

// Градации серого
// ---------------------------------

/// @type Color
$color-black: #000;

/// @type Color
$color-darken: #232323;

/// @type Color
$color-dark: #404040;

/// @type Color
$color-gray: #787878;

/// @type Color
$color-middle: #a2a2a2;

/// @type Color
$color-light: #ddd;

/// @type Color
$color-lighten: #f6f7f9;

/// @type Color
$color-white: #fff;


// Цветовые статусы
// ---------------------------------

/// @type Color
$color-default: #bbc8d2;

/// @type Color
$color-primary: #8074ff;

/// @type Color
$color-secondary: #e652a6;

/// @type Color
$color-success: #0cc190;

/// @type Color
$color-info: #00b3fe;

/// @type Color
$color-warning: #ffd1a2;

/// @type Color
$color-danger: #ff6262;



/// Палитра цветов:
/// статусs и градациb серого
/// @type Map карта
$palette: (
	black: $color-black,
	darken: $color-darken,
	dark: $color-dark,
	gray: $color-gray,
	middle: $color-middle,
	light: $color-light,
	lighten: $color-lighten,
	white: $color-white,

	default: $color-default,
	primary: $color-primary,
	secondary: $color-secondary,
	success: $color-success,
	info: $color-info,
	warning: $color-warning,
	danger: $color-danger,
);

/// Палитра цветов сайта:
/// основной и оттенки,
/// дополнительный и оттенки (+инверсный)
/// акцентный и оттенки
/// @type Map карта
$palette-site: (
	main: #034ea0,
	main-lightest: #00bef1,
	main-light: #3f86c6,
	main-dark: #000, // пока не используется
	main-darkest: #0f1e50,
	subsidiary: #e4c4c0,
	subsidiary-light: #f1e1df,
	subsidiary-dark: #000, // пока не используется
	subsidiary-inverse: #444966,
	accent: #e16d3e,
	accent-light: #ffd1a2,
	accent-dark: #ec5d2b,
);

/// Палитра цветов типографики
/// заголовки, параграфы, ссылки
/// @type Map карта
$palette-typography: (
	heading: #3c4858,
	text: #656c7a,
	sub-text: #96999d,
	link-light: #2687fb,
	link-dark: #3f86c6,
);

/// Палитра цветов карточек анкет и вакансий
/// заголовки, текст, статусы
/// @type Map карта
$palette-cards: (
	vip: #ff9000,
	vip-user-bg: #fdf8ec,
	vip-user-meta-bg: #fefcf6,

	vip-vacancy-bg: #eff8ff,
	vip-vacancy-meta-bg: #f7fcff,

	top: #e16d3e,
	top-meta-bg: #fcf0eb,

	hot: #a52d40,
	hot-meta-bg: #faf3f2,

	normal: #444966,
	normal-meta-bg: #ececef,
);

/// Палитра цветов соц. сетей
/// @type Map карта
$palette-social: (
	blogger: #f58220,
	bobrdobr: #763c34,
	delicious: #003bd8,
	digg: #165495,
	evernote: #75bb1e,
	facebook: #3b5998,
	formspring-me: #4693d7,
	google-bookmarks-old: #246df8,
	google-plus: #dd5044,
	instagram: #325c86,
	instapaper: #6b6b6b,
	juick: #b98337,
	linked-in: #007ab5,
	liveinternet: #27819b,
	livejournal: #6899c4,
	mail-ru: #2f69a1,
	memori: #fd2668,
	myspace: #1d1d1d,
	odnoklassniki: #f58220,
	pinterest: #cb2027,
	readability: #9d0000,
	rss: #fb7629,
	springpad: #f7b42f,
	stumbleupon: #ed4713,
	surfingbird: #14aef8,
	tumblr: #2e4e67,
	twitter: #64a8f2,
	vk: #41658b,
	webdiscover: #8bc514,
	yahoo: #7b0099,
	youtube: #cd201f
);



// ==================================
//  Переназначаем переменные в файлах
// ==================================
@import 'overwrite-vars-in-files';


// ==================================
//  Пользовательский конифг
// ==================================
@import 'custom-config';


// ================================
//  Импортируем глобальные функции и миксины
// ================================
@import 'import-functions-and-mixins';
