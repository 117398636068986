html {
  font-family: "MuseoSansCyrl", sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: #2687fb;
  outline: none;
}

a:hover {
  color: #3f86c6;
  outline: none;
}

button {
  outline: none;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  transition: opacity .2s ease;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #888;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

hr {
  width: 100%;
  height: 1px;
  margin: 2rem 0;
  border: 0;
  background-color: #f1e1df;
}

#go-top {
  display: block;
  position: fixed;
  right: -45px;
  bottom: 17px;
  z-index: 3;
  width: 44px;
  height: 44px;
  background-color: #444966;
  border-radius: 8px 0 0 8px;
  transition: .15s ease-in-out;
}

#go-top:after {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 7px);
  z-index: 1;
  transform: rotate(45deg);
  border-color: #e4c4c0;
  border-style: solid;
  border-width: 3px 0 0 3px;
  border-radius: 4px;
  transition: .15s ease-in-out;
}

#go-top:hover {
  background-color: #e4c4c0;
}

#go-top:hover:after {
  border-color: #444966;
}

#go-top.is-visible {
  right: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-upper {
  text-transform: uppercase;
}

._fw300 {
  font-weight: 300 !important;
}

.gcell--bg-white {
  background-color: #fff;
}

._ovh {
  overflow: hidden;
}

._ovh\! {
  overflow: hidden !important;
}

@media only screen and (min-width: 1024px) {
  ._db\! {
    display: block !important;
  }
}

.js-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.max-height--sm {
  max-height: 127.2727273px;
}

@media only screen and (min-width: 420px) {
  .max-height--sm {
    max-height: 210px;
  }
}

.max-height--md {
  max-height: 190.9090909px;
}

@media only screen and (min-width: 420px) {
  .max-height--md {
    max-height: 315px;
  }
}

.max-height--def {
  max-height: 254.5454545px;
}

@media only screen and (min-width: 420px) {
  .max-height--def {
    max-height: 420px;
  }
}

.max-height--lg {
  max-height: 381.8181818px;
}

@media only screen and (min-width: 420px) {
  .max-height--lg {
    max-height: 630px;
  }
}

.max-height--xl {
  max-height: 509.0909091px;
}

@media only screen and (min-width: 420px) {
  .max-height--xl {
    max-height: 840px;
  }
}

.error-view {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #ddd;
  color: #000;
}

.error-view__block {
  text-align: left;
  width: 90%;
  max-width: 580px;
  margin: 1rem auto;
}

.error-view__code {
  font-weight: bold;
  font-size: 8rem;
  margin-right: 2rem;
  line-height: 1;
  text-align: right;
}

@media only screen and (min-width: 1280px) {
  .error-view__code {
    font-size: 12rem;
  }
}

.error-view__msg {
  margin-top: -2em;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 1em 2em;
  line-height: 1.6;
}

@media only screen and (min-width: 1280px) {
  .error-view__msg {
    margin-top: -3rem;
    padding: 2rem;
    line-height: 1.4;
  }
}
