////
/// @group style.scss
////


// Config
// ================================
	@import 'config';

	@include normalize__document;
	@include normalize__sections;
	@import '_partials/base';



// Custom css
// ================================
	.error-view {
		width: 100%;
		height: 100%;
		text-align: center;
		background-color: $color-light;
		color: $color-black;

		&__block {
			text-align: left;
			width: 90%;
			max-width: 580px;
			margin: 1rem auto;
		}

		&__code {
			font-weight: bold;
			font-size: 8rem;
			margin-right: 2rem;
			line-height: 1;
			text-align: right;
			@include media($lg) {
				font-size: 12rem;
			}
		}

		&__msg {
			margin-top: -2em;
			background-color: #fff;
			box-shadow: 0 3px 10px rgba(#000, .2);
			padding: 1em 2em;
			line-height: 1.6;
			@include media($lg) {
				margin-top: -3rem;
				padding: 2rem;
				line-height: 1.4;
			}
		}
	}

