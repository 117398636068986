////
/// @group _partials/base.scss
////

// vars
// ================================

/// @type Size
$base__body__min-width: 320px !default;

/// @type Boolean
$base__font-size: false !default;

/// @type Size
$base__body__breakpoint: 1024px !default;

/// @type Boolean
$base__font-size-mq: false !default;

/// @type Color
$base__color-link: color-typography(link-light) !default;

/// @type Color
$base__color-link-visited: darken($base__color-link, 20%) !default;

/// @type Color
$base__hr__color: #888 !default;

/// @type Color
$base__pre__bg-color: #eee !default;

/// @type Size
$base__pre__padding: 1.2em !default;

/// @type Size
$base__pre__tab-size: 4 !default;

/// @type Color
$base__selection__color: #fff !default;

/// @type Color
$base__selection__bg-color: #2597ff !default;

/// @type Color
$base__placeholder__color: #999 !default;

// scss
// ================================

html {
  position: relative;
  height: 100%;
  @if $base__font-size {
    font-size: $base__font-size;
    @if $base__font-size-mq {
      @include media($base__body__breakpoint) {
        font-size: $base__font-size-mq;
      }
    }
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: color-typography(link-light);
  outline: none;

  &:hover {
    color: color-typography(link-dark);
    outline: none;
  }

  //&:visited {
  //  color: color-typography(link-light);
  //}
}

button {
  outline: none;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: $base__body__min-width;
  transition: opacity .2s ease;
  //opacity: 1 !important;
}

pre {
  padding: $base__pre__padding;
  tab-size: $base__pre__tab-size;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: $base__pre__bg-color;
  word-wrap: break-word;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid $base__hr__color;
}

::selection {
  text-shadow: none;
  color: $base__selection__color;
  background: $base__selection__bg-color;
}

::-moz-selection {
  text-shadow: none;
  color: $base__selection__color;
  background: $base__selection__bg-color;
}

::-ms-selection {
  text-shadow: none;
  color: $base__selection__color;
  background: $base__selection__bg-color;
}

input {
  box-shadow: none;
  @include placeholder($base__placeholder__color, true);
  @include placeholder-on-focus();
}

textarea {
  box-shadow: none;
  @include placeholder($base__placeholder__color, true);
  @include placeholder-on-focus();
}

hr {
  width: 100%;
  height: 1px;
  margin: rem(32) 0;
  border: 0;
  background-color: color-site(subsidiary-light);
}

#go-top {
  display: block;
  position: fixed;
  right: -45px;
  bottom: 17px;
  z-index: 3;
  width: 44px;
  height: 44px;
  background-color: color-site(subsidiary-inverse);
  border-radius: 8px 0 0 8px;
  transition: .15s ease-in-out;

  &:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 7px);
    z-index: 1;
    transform: rotate(45deg);
    border-color: color-site(subsidiary);
    border-style: solid;
    border-width: 3px 0 0 3px;
    border-radius: 4px;
    transition: .15s ease-in-out;
  }

  &:hover {
    background-color: color-site(subsidiary);
    &:after {
      border-color: color-site(subsidiary-inverse);
    }
  }

  &.is-visible {
    right: 0;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-upper {
  text-transform: uppercase;
}

._fw300 {
  font-weight: 300 !important;
}

.gcell--bg-white {
  background-color: #fff;
}

._ovh {
  overflow: hidden;
}

._ovh\! {
  overflow: hidden !important;
}

@media only screen and (min-width: 1024px) {
  ._db\!{
    display: block !important;
  }
}

.js-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}


.max-height {
  $maxH-step: 210;

  $sm: $maxH-step * 1 + 0px;
  $md: $maxH-step * 1.5 + 0px;
  $def: $maxH-step * 2 + 0px;
  $lg: $maxH-step * 3 + 0px;
  $xl: $maxH-step * 4 + 0px;

  &--sm {
    max-height: $sm / 1.65;
    @include media($def) {
      max-height: $sm;
    }
  }
  &--md {
    max-height: $md / 1.65;
    @include media($def) {
      max-height: $md;
    }
  }
  &--def {
    max-height: $def / 1.65;
    @include media($def) {
      max-height: $def;
    }
  }
  &--lg {
    max-height: $lg / 1.65;
    @include media($def) {
      max-height: $lg;
    }
  }
  &--xl {
    max-height: $xl / 1.65;
    @include media($def) {
      max-height: $xl;
    }
  }
}
